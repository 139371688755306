import cn from 'classnames';
import React from 'react';
import slugify from 'react-slugify';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import ProductCard from '@/app/_components/product-card/ProductCard';
import { ProductCategory } from '@/app/_types/components/product-category';
import { ProductCategoryHeader } from '@/app/_types/components/product-category-header';
import { FilesMeta } from '@/app/_types/file';

import Styles from './CtaBanner.module.scss';

type CtaBannerTypes = {
  banner: ProductCategoryHeader;
  subCategories?: ProductCategory[] | null;
  filesMeta?: FilesMeta;
};

const CtaBanner = ({ banner, subCategories, filesMeta }: CtaBannerTypes) => {
  const { title, paragraph, button, title_2 } = banner;

  return (
    <div className={Styles.ctaBannerWrapper}>
      <div className="container">
        <div className={Styles.ctaBannerContent}>
          <div className="headingElement">
            {(title || title_2) && (
              <h1
                className={cn(
                  'titleXXl text-primary strong',
                  Styles.ctaBannerTitle,
                )}
              >
                {title_2 ? (
                  <>
                    <span className="light">{title}</span> {title_2}
                  </>
                ) : (
                  title
                )}
              </h1>
            )}
          </div>

          {paragraph && (
            <div
              className={cn(Styles.description, 'editorDescription')}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            ></div>
          )}
          {button && (
            <ButtonCustom
              btnText={button?.text}
              href={button?.url || undefined}
              newTab={button?.new_tab}
            />
          )}
        </div>
      </div>
      <div className="container">
        {subCategories && (
          <div className={Styles.productCards}>
            {subCategories.map((category, index) => (
              <ProductCard
                key={index}
                url={`#${slugify(category.title)}`}
                title={category.title}
                icon={category.icon}
                paragraph={category.description}
                filesMeta={filesMeta}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CtaBanner;
