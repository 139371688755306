'use client';

import { usePathname } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';
import slugify from 'react-slugify';

import { FilesMeta } from '@/app/_types/file';

import Brand from './brand/Brand';
import History from './history/History';
import Leadership from './leadership/Leadership';
import Styles from './WhoWeAre.module.scss';

type WhoWeAreTypes = {
  preText?: string | null;
  title?: string | null;
  details?: string;
  tabs: Array<{
    key?: string;
    title?: string;
    content?: any;
  }>;
  filesMeta?: FilesMeta;
};

const WhoWeAre = (props: WhoWeAreTypes) => {
  const { preText, title, details, tabs } = props;

  const [activeTab, setActiveTab] = useState(0);
  const pathName = usePathname();
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const offeringListRef = useRef<HTMLDivElement | null>(null);
  const offeringContentContainerRef = useRef<HTMLDivElement | null>(null);
  const stickyWrapperRef = useRef<HTMLDivElement | null>(null);

  const historyRef = useRef<HTMLDivElement | null>(null);

  const offset = 120;

  useEffect(() => {
    const hash = window.location.hash;
    const activeSection = hash.replace('#', '');

    if (activeSection === slugify(tabs[0]?.title) && historyRef.current) {
      const scrollPosition = historyRef.current.offsetTop - offset;

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, [pathName]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);

    if (offeringListRef.current) {
      offeringListRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    tabRefs.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!offeringContentContainerRef.current || !stickyWrapperRef.current)
        return;

      const stickyWrapperRect =
        stickyWrapperRef.current.getBoundingClientRect();
      const contentRect =
        offeringContentContainerRef.current.getBoundingClientRect();

      if (
        stickyWrapperRect.top <= 0 &&
        stickyWrapperRect.bottom >= contentRect.height
      ) {
        offeringContentContainerRef.current.classList.add(Styles.fixedPosition);
      } else {
        offeringContentContainerRef.current.classList.remove(
          Styles.fixedPosition,
        );
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderContent = () => {
    switch (tabs[activeTab]?.key) {
      case 'history':
        return <History content={tabs[activeTab].content} />;
      case 'brand':
        return (
          <Brand
            content={tabs[activeTab].content}
            filesMeta={props.filesMeta}
          />
        );
      case 'leadership':
        return <Leadership content={tabs[activeTab].content} />;
      default:
        return <History content={tabs[activeTab].content} />;
    }
  };

  return (
    <div
      className="pageSection"
      ref={historyRef}
      id={tabs[0]?.title ? slugify(tabs[0]?.title) : ''}
    >
      <div className={Styles.offeringWrapper}>
        <div className="container">
          <div className={Styles.offeringContentHeadingSection}>
            {title && (
              <div className="headingElement">
                {preText && <h3 className="subTitle">{preText}</h3>}
                {title && <h2 className="titleXl">{title}</h2>}
              </div>
            )}
            {details && (
              <div
                className={Styles.offeringContentDetails}
                dangerouslySetInnerHTML={{ __html: details }}
              ></div>
            )}
          </div>
        </div>
        <div ref={stickyWrapperRef} className={Styles.stickyWrapper}>
          <div className="container">
            <div className={Styles.navContainer}>
              <div
                ref={offeringContentContainerRef}
                className={Styles.offeringContentContainer}
              >
                <div className={Styles.tabNavigation}>
                  {tabs.map((tab, index) => (
                    <button
                      key={index}
                      ref={(el) => {
                        if (el) {
                          tabRefs.current[index] = el;
                        }
                      }}
                      className={`${Styles.tabButton} ${activeTab === index ? Styles.active : ''}`}
                      onClick={() => handleTabClick(index)}
                    >
                      <h3>{tab.title}</h3>
                    </button>
                  ))}
                </div>
              </div>

              <div ref={offeringListRef} className={Styles.offeringList}>
                {renderContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
