'use client';
import Image from 'next/image';
import React, { useState } from 'react';

import { useWindowSize } from '@/app/_hooks/window-size.hook';
import { VendorCategory } from '@/app/_types/components/vendor-category';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './ServicesButtons.module.scss';

type ServicesButtonsTypes = {
  services?: VendorCategory;
  filesMeta: FilesMeta;
  onSelectCategory: (categoryType: string) => void;
};

const ServicesButtons = (props: ServicesButtonsTypes) => {
  const { services, filesMeta, onSelectCategory } = props;

  const [selectedService, setSelectedService] = useState<string | null>(null);

  const size = useWindowSize();

  const getChunkSize = () => {
    if (size.width && size.width <= 480) {
      return 1;
    } else if (size.width && size.width <= 992) {
      return 2;
    } else {
      return 4;
    }
  };

  const chunkArray = (
    arr: Array<{ image: string; category_type: string }>,
    chunkSize: number,
  ) => {
    const result = [];

    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  const handleSelectService = (title: string) => {
    setSelectedService(title);
    onSelectCategory(title);
  };

  const chunkSize = getChunkSize();
  const groupedItems = chunkArray(
    services as { image: string; category_type: string }[],
    chunkSize,
  );

  return (
    <div className={Styles.servicesSectionWrapper}>
      <div className={Styles.gridContainer}>
        {groupedItems.map((group, index) => (
          <div key={index} className={Styles.gridRow}>
            {group.map((item, i) => (
              <label
                key={i}
                className={`${Styles.gridItem} ${selectedService === item.category_type ? Styles.active : ''}`}
              >
                <input
                  type="radio"
                  name="services"
                  className={Styles.gridRadio}
                  value={item.category_type}
                  checked={selectedService === item.category_type}
                  onChange={() => handleSelectService(item.category_type)}
                />
                <Image
                  src={getDirectusAssetURI(item.image) || ''}
                  alt={getAltProperty(filesMeta, item.image ?? '', 'icon')}
                  height={100}
                  width={100}
                  className={Styles.gridImage}
                />
                <div className={Styles.title}>{item.category_type}</div>
              </label>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesButtons;
