'use client';

import { RightOutlined } from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import { useState } from 'react';

import {
  NavigationItem,
  NavigationItemChildrenM2M,
} from '@/app/_types/components/navigation-item';
import { NavigationItemM2M } from '@/app/_types/footer';

import Styles from './FooterMenuMobile.module.scss';

type MenuItem = Required<MenuProps>['items'][number];

type Props = {
  items?: NavigationItemM2M[] | null;
};

interface LevelKeysProps {
  key?: string;
  children?: LevelKeysProps[];
}

const FooterMenuMobile = ({ items }: Props) => {
  const [stateOpenKeys, setStateOpenKeys] = useState<string[]>([]);

  const transformMenuItem = (
    key: string,
    item: NavigationItem,
    level: number = 1,
  ): MenuItem => {
    const { Title, navigation } = item;
    const href = navigation.permalink || '';

    const children =
      item.children && item.children.length > 0
        ? transformSubMenuData(item.children, key, level + 1) || undefined
        : undefined;

    return {
      key,
      label: (
        <div className={Styles.menuItem}>
          {href ? (
            <a
              href={href}
              className={Styles.menuLink}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {Title}
            </a>
          ) : (
            <span className={Styles.menuText}>{Title}</span>
          )}
          {item.children && (
            <button
              className={'submenuToggle'}
              onClick={(e) => {
                e.stopPropagation();
                setStateOpenKeys((prev) =>
                  prev.includes(key)
                    ? prev.filter((k) => k !== key)
                    : [...prev, key],
                );
              }}
            >
              <RightOutlined />
            </button>
          )}
        </div>
      ),
      children: children,
    };
  };

  const transformMenuData = (
    items: NavigationItemM2M[],
  ): ItemType<MenuItemType>[] => {
    return items.map((itemM2A) =>
      transformMenuItem(
        String(itemM2A.navigation_items_id.id),
        itemM2A.navigation_items_id,
      ),
    );
  };

  const transformSubMenuData = (
    items: NavigationItemChildrenM2M[],
    key: string,
    level: number = 1,
  ): ItemType<MenuItemType>[] => {
    return [
      ...items.map((item) =>
        transformMenuItem(
          String(item.related_navigation_items_id.id),
          item.related_navigation_items_id,

          level,
        ),
      ),
    ].filter(Boolean);
  };

  const menuItems: MenuItem[] = transformMenuData(items || []);

  const getLevelKeys = (items1: LevelKeysProps[]) => {
    const key: Record<string, number> = {};
    const func = (items2: LevelKeysProps[], level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };

    func(items1);
    return key;
  };
  const levelKeys = getLevelKeys(menuItems as LevelKeysProps[]);

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys: string[]) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1,
    );

    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <div className={Styles.subMenuWrapper}>
      <div className={Styles.subMenuContainer}>
        <Menu
          mode="inline"
          openKeys={stateOpenKeys}
          onOpenChange={onOpenChange}
          items={menuItems}
          className={Styles.subMenuList}
          expandIcon={''}
        />
      </div>
    </div>
  );
};

export default FooterMenuMobile;
