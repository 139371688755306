'use client';

import cn from 'classnames';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import React, { useEffect, useRef } from 'react';
import slugify from 'react-slugify';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { CopyComponentM2M } from '@/app/_types/components/copy-component';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './GuidelinesSection.module.scss';

type GuidelinesSectionTypes = {
  claimsList: CopyComponentM2M[];
  title?: string | null;
  preText?: string | null;
  filesMeta?: FilesMeta;
};

const GuidelinesSection = (props: GuidelinesSectionTypes) => {
  const { claimsList, title, preText, filesMeta = {} } = props;

  const pathName = usePathname();

  const claimsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const offset = 120;
    const hash = window.location.hash;
    const activeSection = hash.replace('#', '');

    if (activeSection === slugify(title) && claimsRef.current) {
      const scrollPosition = claimsRef.current.offsetTop - offset;

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, [pathName]);

  return (
    <div
      className={cn('pageSection', Styles.guidelinesSection)}
      id={slugify(title)}
      ref={claimsRef}
    >
      <div className="container">
        <div className={Styles.guidelinesSectionWrapper}>
          <div className={Styles.sectionHeadingContainer}>
            <div className={Styles.sectionLeftContent}>
              <div className="headingElement">
                {preText && <h3 className="subTitle">{preText}</h3>}
                {title && <h2 className="titleXl">{title}</h2>}
              </div>
            </div>
          </div>
          {claimsList && (
            <div className={Styles.guidelinesList}>
              {claimsList.map(({ copy_component_id }, index) => (
                <div key={index} className={Styles.guidelineItem}>
                  <div className={Styles.guidelineImageContainer}>
                    <Image
                      src={
                        getDirectusAssetURI(copy_component_id?.image ?? '') ??
                        ''
                      }
                      alt={getAltProperty(
                        filesMeta,
                        copy_component_id?.image ?? '',
                        'Image',
                      )}
                      className={Styles.guidelineImage}
                      width={574}
                      height={374}
                    />
                  </div>
                  <div className={Styles.guidelineContent}>
                    {copy_component_id?.title && (
                      <div className="headingElement">
                        <h3 className="titleLg">{copy_component_id?.title}</h3>
                      </div>
                    )}
                    {copy_component_id?.paragraph && (
                      <div
                        className={Styles.guidelineParagraph}
                        dangerouslySetInnerHTML={{
                          __html: copy_component_id?.paragraph,
                        }}
                      ></div>
                    )}

                    {copy_component_id?.cta_text &&
                      copy_component_id?.cta_url && (
                        <div className={Styles.guidelineFooter}>
                          <ButtonCustom
                            btnText={copy_component_id?.cta_text}
                            href={copy_component_id?.cta_url}
                            newTab={copy_component_id?.cta_new_tab}
                          />
                        </div>
                      )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuidelinesSection;
