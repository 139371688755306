'use client';
import cn from 'classnames';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { FilesMeta } from '@/app/_types/file';
import { HomeVariation1 } from '@/app/_types/home';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './CtaBanner.module.scss';

type DynamicTitle = { option: string };

type CtaBannerProps = HomeVariation1['header_component'] & {
  filesMeta: FilesMeta;
  dynamic_titles?: DynamicTitle[];
};

const CtaBanner = (props: CtaBannerProps) => {
  const {
    static_title,
    paragraph,
    cta_button,
    image,
    dynamic_titles = [],
    filesMeta,
  } = props || {};

  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;

      if (scroll >= 175) {
        setScrollPosition(scroll - 175);
      } else {
        setScrollPosition(0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Rotate dynamic_titles items one by one with fade animation
  useEffect(() => {
    if (dynamic_titles.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % dynamic_titles.length);
      }, 2000);

      return () => clearInterval(intervalId);
    }
  }, [dynamic_titles]);

  const zoomStyle = {
    transform: `scale(${1 + scrollPosition / 4000})`,
  };

  return (
    <div className={Styles.ctaBannerWrapper}>
      <div className="container">
        <div className={Styles.ctaBannerContainer}>
          <div className={Styles.ctaBannerContent}>
            <div className={Styles.ctaBannerHeadingContainer}>
              <div className="headingElement">
                {static_title && <h1 className="titleXXXl">{static_title}</h1>}
              </div>

              <div className={cn('headingElement', Styles.offeringText)}>
                {dynamic_titles.map((text, index) => (
                  <div
                    key={index}
                    className={cn(
                      Styles.offeringTextItem,
                      index === currentIndex && Styles.active, // only active item is visible
                    )}
                  >
                    <h2 className="titleXXXl">{text.option}</h2>
                  </div>
                ))}
              </div>
            </div>

            {paragraph && (
              <div
                className={cn(Styles.description, 'editorDescription')}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              ></div>
            )}

            {cta_button && (
              <ButtonCustom
                href={cta_button?.url || undefined}
                btnText={cta_button?.text}
                newTab={cta_button?.new_tab}
              />
            )}
          </div>
          {image && (
            <div className={Styles.bannerImageContainer}>
              <div className={Styles.bannerImageWrapper} style={zoomStyle}>
                <Image
                  src={getDirectusAssetURI(image) ?? ''}
                  alt={getAltProperty(filesMeta, image ?? '', 'Banner')}
                  fill
                  className={Styles.bannerImage}
                  priority
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CtaBanner;
