'use client';
import Image from 'next/image';
import React from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import WarningImage from '@/app/_images/warning.png';
import { getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './NoResultsFound.module.scss';

export interface NoResultsDataType {
  image: string;
  title: string;
  paragraph: string;
  btnText: string;
  url: string;
}

interface NoResultsFoundProps {
  noResultsData: NoResultsDataType;
}

const NoResultsFound = ({ noResultsData }: NoResultsFoundProps) => {
  const { image, title, paragraph, btnText, url } = noResultsData;

  const imgUrl = getDirectusAssetURI(image) || WarningImage;

  return (
    <div className={Styles.noResultsWrapper}>
      <Image src={imgUrl} alt="Warning" height={160} width={160} />
      <div className={Styles.noResultsContent}>
        <div className="headingElement">
          <h2 className="titleLg">{title || 'Oh No!'}</h2>
        </div>
        <p>
          {paragraph ||
            'There&apos;s nothing here that matches your search, please try edit your search or filters'}
        </p>
      </div>
      <ButtonCustom btnText={btnText || 'Back to Home'} href={url || '/'} />
    </div>
  );
};

export default NoResultsFound;
