'use client';

import React, { useRef } from 'react';
import SwiperCore from 'swiper';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import MediaCard from '@/app/_components/media-card/MediaCard';
import { useWindowSize } from '@/app/_hooks/window-size.hook';
import { Blog } from '@/app/_types/blog';
import { MediaResource } from '@/app/_types/components/media-resources';

import Styles from './RelatedArticles.module.scss';

import 'swiper/css';

SwiperCore.use([Mousewheel]);

type Props = {
  media?: Blog[] | null | MediaResource[];
  blog_title?: string | null;
  blog_pre_text?: string | null;
  blogBaseUrl?: string | null;
};

const RelatedArticles = (props: Props) => {
  const { media, blogBaseUrl } = props;
  const swiperRef = useRef<SwiperCore | null>(null);
  const { width } = useWindowSize();

  const mappedMedia =
    media &&
    media.map((item) => ({
      blog_id: {
        title: item.title,
        date: item?.date,
        author: item.author,
        banner_image: item.banner_image,
        slug: item.slug,
        tags: 'tags' in item ? item.tags : [],
      },
    }));

  return (
    <div className={Styles.relatedArticlesWrapper}>
      <div className="container">
        <div className={Styles.sectionHeadingContainer}>
          <div className={Styles.sectionLeftContent}>
            <div className="headingElement">
              <h3 className="subTitle">Media</h3>
              <h2 className="titleXl">You may also like</h2>
            </div>
          </div>
        </div>
      </div>

      <div className={Styles.mediaList}>
        {width && width < 640 ? (
          <>
            {mappedMedia &&
              mappedMedia.map((item, index) => (
                <MediaCard
                  title={item.blog_id?.title}
                  date={item.blog_id?.date}
                  author={item.blog_id?.author}
                  image={item.blog_id?.banner_image}
                  slug={item.blog_id?.slug}
                  baseUrl={blogBaseUrl}
                  landscapeMode={true}
                  shadowBox={true}
                  key={index}
                />
              ))}
          </>
        ) : (
          <Swiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            spaceBetween={40}
            slidesPerView={1}
            freeMode={true}
            mousewheel={true}
            breakpoints={{
              640: {
                slidesPerView: 'auto',
                spaceBetween: 0,
              },
            }}
            className={Styles.mySwiper}
          >
            {mappedMedia &&
              mappedMedia.map((item, index) => (
                <SwiperSlide key={index} className={Styles.swiperSlide}>
                  <MediaCard
                    key={index}
                    title={item.blog_id?.title}
                    date={item.blog_id?.date}
                    author={item.blog_id?.author}
                    image={item.blog_id?.banner_image}
                    landscapeMode={true}
                    shadowBox={true}
                    slug={item.blog_id?.slug}
                    baseUrl={blogBaseUrl}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default RelatedArticles;
